import styled, { createGlobalStyle } from "styled-components";
import GTAmericaRegular from '@assets/fonts/GT-America-Regular.woff';


export const GlobalStyle = createGlobalStyle`
  @font-face {
        font-family: 'GTAmerica';
        src: local('GTAmerica'), local('FontName'),
        url(${GTAmericaRegular}) format('woff');
        font-style: normal;
    }
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
  }

  body {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'GTAmerica';
  }

  div#root {
    height: 100%;
  }
`;

export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Preview = styled.img`
  width: 100%;
  height: auto;
`;
 
