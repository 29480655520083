import styled, { keyframes, css } from "styled-components";
import bgimg from '@assets/bg/swipe-landing_1.png';

const slideUp = keyframes`
0% {
  transform: translateY(100%);
}
100% {
  transform: translateY(0);
}
`
export const Wrapper = styled.div`

  background-image: url(${bgimg});
  background-position: center 85% ;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: 590px;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 962px;
  width: 100%;
  max-height: 962px;
  transition-property: all;
	transition-duration: .8s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: 9999;

  &.slide-up {
    max-height: 0;
  }
  &.destroyed {
    display: none;
  }
`;

export const SwipeBtn = styled.div`
    width: 100%;
    padding: 20px 0 60px;
    color: white;
    font-size: .85em;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 40px;
      margin-bottom: 30px;
    }
`

