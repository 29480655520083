import { useState, useEffect } from "react";

/**
 * calculate offsets to center the container in the middle of the video.
 **/
export function useOffsets(vWidth, vHeight, cWidth, cHeight) {
  const [offsets, setOffsets] = useState({ x: 0, y: 0 });

  useEffect(() => {
    console.log(vWidth, vHeight, cWidth, cHeight) /* 760 570 570 570*/
    if (vWidth && vHeight && cWidth && cHeight) {
      const x = vWidth > cWidth ? Math.round((vWidth - cWidth) / 2) : 0; /*  */
      const y = vHeight > cHeight ? Math.round((vHeight - cHeight) / 2) : 0;

      setOffsets({ x, y });
      /* can use css instead
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      */
    }
  }, [vWidth, vHeight, cWidth, cHeight]);

  return offsets;
}
