import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { FrameOverlay, FramesSelection, FramedImage, Image, Carouselcontainer } from "./styles";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob } from 'html-to-image';
import { Rnd } from "react-rnd";

export function Frames({ onStart, currentImage, maxWidth, maxHeight, step, framesstickers, createImage, setCurrentImage }) {
    
    const { t, i18n } = useTranslation();
    const [images, setImages] = useState([])
    
    const ImageType = { id: Number, url: String };
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState();
    const [createdimage, setCreatedImage] = useState();
    const [stickers, setStickers] = useState([]);
    const [isLastImage, setIsLastImage] = useState(false)
    const carouselItemsRef = useRef([]);
    
    useEffect(() => {
        setImages(framesstickers)
    }, [])
    useEffect(() => {
        createImage()
    }, [images])
    useEffect(() =>{
        if (images && images[0]) {
            setSelectedImageIndex(0);
            setSelectedImage(images[0]);
        }
    }, [images])
    function filter (node) { 
        return (node.tagName !== 'I' && node.className !== 'rsz-wrapper');
    }
    function createImage() {
        htmlToImage.toPng(document.getElementById('framedImage'), { filter: filter })
        .then(function (dataUrl) { 
            if (step === 2) {
                setCurrentImage(dataUrl) 
            } else if(step === 3) {
                setCurrentImage(dataUrl) 
            }
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        })
      }
    function handleSelectedImageChange(newIdx) {
        if (images && images.length > 0) {
            setSelectedImage(images[newIdx])
            setSelectedImageIndex(newIdx)
            createImage()
            if (carouselItemsRef?.current[newIdx]) {
                carouselItemsRef?.current[newIdx]?.scrollIntoView({
                    inline: "center",
                    behavior: "smooth"
                });
            }
            console.log(selectedImageIndex)
            console.log(images.length - 2)
            console.log('isLastImage', selectedImageIndex === (images.length - 2))
            setIsLastImage(selectedImageIndex === (images.length - 2))
        }
    };

    function handleAddSticker(newIdx, id) {
        const newStickerObj = {
            id: Math.floor(Math.random()*100) + id,
            url: images[newIdx].url
        }
        setStickers([...stickers, newStickerObj])
    }
    function handleRemoveSticker(idx, id) {
        const newStickers = stickers.filter((item, index) => id !== item.id );
        setStickers(newStickers)
    }

    function handleRightClick() {
        if (images && images.length > 0) {
            let newIdx = selectedImageIndex + 1;
            if (newIdx >= images.length) {
            newIdx = 0;
            }
            handleSelectedImageChange(newIdx);
        }
    };

    function handleLeftClick() {
        if (images && images.length > 0) {
            let newIdx = selectedImageIndex - 1;
            if (newIdx < 0) {
              newIdx = images.length - 1;
            }
            handleSelectedImageChange(newIdx);
        }
    };
    function handleSelectFrame(e) {

    }
    
    return (
        <FramesSelection className="frames-selection">
            <Carouselcontainer className="carousel-container" maxWidth={maxWidth && maxWidth}>
                <FramedImage id="framedImage">
                    <Image style={{ backgroundImage: `url(${currentImage})` }} />
                    {stickers && stickers.map((item, idx) => 
                        <Rnd
                            key={item.id}
                            resizeHandleWrapperClass= "rsz-wrapper"
                            resizeHandleClasses = {{
                                top: "rsz-top",
                                right: "rsz-right",
                                bottom: "rsz-bottom",
                                left: "rsz-left",
                                bottomLeft: "rsz rsz-bl",
                                bottomRight: "rsz rsz-br", 
                                topLeft: "rsz rsz-tl",
                                topRight: "rsz rsz-tr",
                            }}
                            onDragStop={(ref) => { 
                                createImage()
                            }}
                            onResizeStop={createImage}
                            className={`resize-drag step-${step}`}
                            default={{
                            x: 0,
                            y: 0,
                            width: step === 3 ? maxWidth / 2.5 : maxWidth,
                            height: step === 3 ? maxHeight / 2.5 : maxHeight
                            }}
                            bounds="parent"
                        >
                            <div
                                className={`selected-image step-${step}`}
                                style={{ backgroundImage: `url(${item.url})` }}
                            >
                                <i onClick={e => handleRemoveSticker(idx, item.id)} onTouchStart={e => handleRemoveSticker(idx, item.id)} ></i>
                            </div>
                        </Rnd>
                    )}
                    {step === 2 &&
                    <Rnd
                        onDragStop={(ref) => { 
                            createImage()
                        }}
                        onResizeStop={createImage}
                        className={`resize-drag step-${step}`}
                        default={{
                        x: 0,
                        y: 0,
                        width: step === 3 ? maxWidth / 2.5 : maxWidth,
                        height: step === 3 ? maxHeight / 2.5 : maxHeight
                        }}
                        bounds="parent"
                    >
                        <div
                            className={`selected-image step-${step}`}
                            style={{ backgroundImage: `url(${selectedImage?.url})` }}
                        />
                    </Rnd>
                    }
                </FramedImage>
                <div className="carousel">
                    <div className="carousel__images"> 
                        {/* {images &&
                            images.map((image, idx) => (
                            <div
                                onClick={() => handleSelectedImageChange(idx)}
                                style={{ backgroundImage: `url(${image.url})` }}
                                key={image.id}
                                className={`carousel__image ${
                                selectedImageIndex === idx && "carousel__image-selected"
                                }`}
                                ref={el => carouselItemsRef.current[idx] = el}
                            />
                            ))} */}
                        {images.map((img, idx) => 
                            <div
                            key={img.id}
                            style={{ backgroundImage: `url(${img.url})` }}
                            className={`carousel__image ${
                                selectedImageIndex === idx && "carousel__image-selected"
                                }`}
                            onClick={(e) => handleSelectedImageChange(idx)}
                            onTouchStart={(e) => handleSelectedImageChange(idx)}
                            ref={el => (carouselItemsRef.current[idx]) = el}
                            >
                            {idx === 0 && img.url === "" && <strong>NO FRAME</strong>}
                            {
                                step === 3 && selectedImageIndex === idx &&
                                <span onClick={e => handleAddSticker(idx, img.id)}>ADD</span>
                            }
                            </div>
                        )}
                    </div>
                    
                    {
                        selectedImageIndex > 4 &&
                        <button
                        className="carousel__button carousel__button-left"
                        onClick={handleLeftClick} 
                        ></button>
                    }
                    { !isLastImage &&
                        <button
                        className="carousel__button carousel__button-right"
                        onClick={handleRightClick} 
                        ></button>
                    }
                </div>
            </Carouselcontainer>
        </FramesSelection>
    );

}

