import React, { useState, useEffect, useRef } from "react";
import Measure from "react-measure";
import { Wrapper, SwipeBtn } from "./styles";
import { useTranslation } from 'react-i18next';
import iconUp from '@assets/icons/icon-up.png';


export function Landing({ onStart }) {

    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [mouseUp, setMouseUp] = useState(0);
    const [mouseDown, setMouseDown] = useState(0);
    const [swipeUp, setSwipeUp] = useState();
    const [destroy, setDestroy] = useState();
    const { t, i18n } = useTranslation();

    useEffect(() => {
       console.log('HELLO! from WEBSTUDIO')
       console.log('swipe to start...')
       return () => {
            setTimeout(() => {
                console.log('cleanup')
                setDestroy(true)
            }, 2000);
        };
    });
    function handleTouchStart(e) {
        console.log(e)
        setTouchStart(e.targetTouches[0].clientY);
    }
    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientY);
    }

    function handleMouseDown(e) {
        console.log(e)
        setSwipeUp(true)
        onStart(true)
        // setMouseDown(e.mouseDownEvent.clientY)
    }
    function handleMouseMove(e) {
        // setMouseUp(e.mouseMoveEvent.clientY)
    }
    
    function handleTouchMouseEnd() {
        console.log(mouseDown)
        if (touchStart - touchEnd > 100) { 
            console.log('up')
            setSwipeUp(true)
            onStart(true)
        }
        if (touchStart - touchEnd < -100) { 
            console.log('down')
        }
        if (mouseDown - mouseUp > 100) {
            console.log('up')
        }
        if (mouseDown - mouseUp < -100) {
            console.log('down')
        }
    }
    function handleResize(contentRect) {
        /* setContainer({
          width: contentRect.bounds.width,
          height: Math.round(contentRect.bounds.width / aspectRatio)
        }); */
      }
    return (
        <Measure bounds onResize={handleResize}>
            {({ measureRef }) => (
                <Wrapper className={[swipeUp ? 'slide-up' : '', destroy ? 'destroyed' : '']}
                onTouchStart={handleTouchStart} 
                onTouchMove={handleTouchMove} 
                onTouchEnd={handleTouchMouseEnd}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleTouchMouseEnd}
                onMouseLeave={handleTouchMouseEnd}
                >
                    {/* <SwipeBtn>
                        <img src={iconUp} />
                        <span>{t('swipe-text')}</span>
                    </SwipeBtn> */}
                </Wrapper>
                
            )}
        </Measure>
        

    );

}

