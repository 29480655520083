import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { Camera } from "@/components/camera";
import { Landing } from "@/components/landing";
import { Root, Preview, GlobalStyle } from "@styles/styles";

function App() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();

  return (
    <Fragment>
      <Root>
        {isCameraOpen && (
          <Camera
            onCapture={blob => setCardImage(blob)}
            onClear={() => setCardImage(undefined)}
          />
        )}
        <Landing onStart={setIsCameraOpen}/>
        {/* {cardImage && (
          <div>
            <Preview src={cardImage && cardImage} />
          </div>
        )} */}
      </Root>
      <GlobalStyle />
    </Fragment>
  );
}


export default App;
