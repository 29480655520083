import styled, { keyframes, css } from "styled-components";
import bgimg from '@assets/bg/family-day-bg-home.png';
import bgcapture from '@assets/icons/icon-camera-family.png';
import bgretry from '@assets/icons/icon-retry.png';
import bgnext from '@assets/icons/icon-next.png';
import bgback from '@assets/icons/icon-back.png';
const wrapperHeight = window.innerHeight >= 962 ? "962px" : "100vh";

const flashAnimation = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
const blinker = keyframes`
to {
    opacity: 0;
  }
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: rgba(154, 7, 22, 0.8);
  height: 65px;
  margin-top: 90px;
  @media only screen and (max-width: 480px) {
    margin-top: 62px;
    height: 55px;
  }
`;
export const Wrapper = styled.div`
  color: #fff;
  max-width: 590px;
  max-height: 962px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-image: url(${bgimg});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  image-rendering: optimizeQuality;
  image-rendering: -webkit-optimize-contrast;
  padding: 0 10px;
  min-height: ${`${wrapperHeight}`};
  height: -webkit-fill-available;
  position: relative;
  @media only screen and (max-width: 480px) {
    min-height: ${`${wrapperHeight}px`};
  }
`;

export const Output = styled.div`

`

/* export const Frames = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-position: bottom;
  z-index: 1;
  opacity: .95;
`; */

export const Loading = styled.span`
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #000;
  font-weight: bold;
  height: ${({ width }) => width && `calc(${width}px - 20px)`};
  i {
    font-style: normal;
    animation: ${blinker} 1s linear infinite;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};
  overflow: hidden;
  @media only screen and (max-width: 480px) {
     height: 400px !important;
  }
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Video = styled.video`
  position: absolute;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  border-radius: 10px;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
  z-index: 2;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-in-out;
      `;
    }
  }}
`;

export const Capture = styled.button`
  padding: 12px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  border: 0;
  font-size: 0;
  background-image: url(${bgcapture});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  image-rendering: optimizeQuality;
  image-rendering: -webkit-optimize-contrast;
  &.retry {
    background-image: url(${bgretry});
  }
  &:disabled {
    opacity: .5;
  }
`;


export const Next = styled.button `
  background-image: url(${bgnext});
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;
  background-color: transparent;
  height: 45px;
  width: 100px;
  cursor: pointer;
  border: 0;
`
export const Prev = styled(Next)`
  background-image: url(${bgback});
  &.disabled {
    opacity: 0;
  }
`

export const Buttons = styled.div `
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-around;
    margin-top: 142px;
    @media only screen and (max-width: 480px) {
      margin-top: 90px;
    }
    .buttons--wrapper {
      flex-grow: 1;
      align-items: center;
      align-content: center;
      display: flex;
      width: 100%;
      justify-content: space-around;
      
      @media only screen and (max-width: 480px) {
        padding: 15px 0;
      }
    }
    &.step {
      &-1 {
        ${Next}, ${Prev} {
          display: none;
        }
      }
      &-2 {
        
      }
    }
`

export const Pagelabel = styled.div`  
  color: #d42229;
  width: 100%;

`