import { useState, useCallback } from "react";

/**
 Height must always be less than the width (regardless of the resolution of the camera). 
 This is achieved by calculating a ratio that is always >= 1 by dividing by the largest dimension.
 **/
export function useCardRatio(initialParams) {
  const [aspectRatio, setAspectRatio] = useState(initialParams);

  const calculateRatio = useCallback((height, width) => {
    if (height && width) {
      console.log(height)
      console.log(width)
      const isLandscape = height <= width;
      console.log('isLandscape', isLandscape)
      const ratio = isLandscape ? width / height : height / width;
      console.log(width/height)
      console.log(height/width)
      console.log(ratio)
      setAspectRatio(ratio);
    }
  }, []);

  return [aspectRatio, calculateRatio];
}
