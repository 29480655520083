import { importAll } from "@hooks/useImportAll";
const stickers = [importAll(require.context('@assets/stickers', false, /\.(png|gif)$/))]
console.log(stickers)
export const stickersData = [
    {
        "id": 1,
        "url": stickers[0]["sticker-1.png"]
    },
    {
        "id": 2,
        "url": stickers[0]["sticker-2.png"]
    },
    {
        "id": 3,
        "url": stickers[0]["sticker-3.png"]
    },
    {
        "id": 4,
        "url": stickers[0]["sticker-4.png"]
    },
    {
        "id": 5,
        "url": stickers[0]["sticker-5.png"]
    },
    {
        "id": 6,
        "url": stickers[0]["sticker-6.png"]
    },
    {
        "id": 7,
        "url": stickers[0]["sticker-7.png"]
    },
    {
        "id": 8,
        "url": stickers[0]["sticker-8.png"]
    },
    {
        "id": 9,
        "url": stickers[0]["sticker-9.png"]
    },
    {
        "id": 10,
        "url": stickers[0]["sticker-10.png"]
    },
    {
        "id": 11,
        "url": stickers[0]["sticker-11.png"]
    },
    {
        "id": 12,
        "url": stickers[0]["sticker-12.png"]
    },
    {
        "id": 13,
        "url": stickers[0]["sticker-13.png"]
    },
    {
        "id": 14,
        "url": stickers[0]["sticker-14.png"]
    },
    {
        "id": 15,
        "url": stickers[0]["sticker-15.png"]
    },
    {
        "id": 16,
        "url": stickers[0]["sticker-16.png"]
    },
    {
        "id": 17,
        "url": stickers[0]["sticker-17.png"]
    }
]
