import React, { useState, useEffect, useRef } from "react";
import { saveAs } from 'file-saver';
import testOutput from '../../assets/bg/family-day-bg-home.png';
import Measure from "react-measure";
import { Wrapper, ShareButtons, Download, Retake } from "./styles";

export function Sharer({currentImage, maxWidth, maxHeight, handleClear}) {

    useEffect(() => {
        // play around this testOutput image for sharing func
        const imageOutput = testOutput
        console.log(currentImage.toString())

    })


    function sharer() {
        
    }

    function download(){
        saveAs(currentImage, 'henkel-family-day.png');
    }
    return (
        <Measure bounds>
            {({ measureRef }) => (
                <Wrapper ref={measureRef}>
                    <img src={currentImage} />
                    <ShareButtons>
                        <Download onClick={download}/>
                        <Retake onClick={handleClear}/>
                    </ShareButtons>
                </Wrapper>
            )}
        </Measure>
        

    );
}