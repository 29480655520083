import { importAll } from "@hooks/useImportAll";
const frameImgs = [importAll(require.context('@assets/frames', false, /\.(png|jpe?g|svg)$/))]

export const framesData = [
    {
        "id": 0,
        "url": ""
    },
    {
        "id": 1,
        "url": frameImgs[0]["frame-1.png"]
    },
    {
        "id": 2,
        "url": frameImgs[0]["frame-2.png"]
    },
    {
        "id": 3,
        "url": frameImgs[0]["frame-3.png"]
    },
    {
        "id": 4,
        "url": frameImgs[0]["frame-4.png"]
    },
    {
        "id": 5,
        "url": frameImgs[0]["frame-5.png"]
    },
    {
        "id": 6,
        "url": frameImgs[0]["frame-6.png"]
    },
    
]
