import React, { useState, useEffect, useRef } from "react";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob } from 'html-to-image';
import { useUserMedia } from "@hooks/useUserMedia";
import { useCardRatio } from "@hooks/useCardRatio";
import { useOffsets } from "@hooks/useOffsets";
import Measure from "react-measure";
import { Frames } from "@components/frames"
import { Sharer } from "@components/sharer";
import { framesData } from "@data/frames";
import { stickersData } from "@data/stickers";

import {
  Header,
  Container,
  Loading,
  Video,
  Flash,
  Canvas,
  Wrapper,
  Capture,
  Output,
  Buttons,
  Next,
  Prev,
  Back,
  Pagelabel
} from "./styles";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "user" }
};
export function Camera({ onCapture, onClear }) {
  
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [step, setStep] = useState(1);
  const [framesstickers, setFramesstickers] = useState(framesData)
  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const [currentImage, setCurrentImage] = useState({})
  const [isFlashing, setIsFlashing] = useState(false);
  const canvasRef = useRef();
  const videoRef = useRef();
  const wrapperRef = useRef();
  
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  )
  useEffect(() => {
    
  }, []);
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }
  function handleCapture() {
    const ctx = canvasRef.current.getContext("2d")
    ctx.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height, 
      0,
      0,
      container.width,
      container.height
    )
    //const blob = await new Promise(resolve => canvasRef.current.toBlob(resolve));
    
    const blob = canvasRef.current.toDataURL()
    setCurrentImage({...currentImage, img1: blob})
    console.log(currentImage)
    setIsCanvasEmpty(false)
    setStep(step + 1)
  }
  
  
  function handleNext() {
    // setStep(step + 1)
    console.log(step)
    if (step === 2) {
      /* set stickers */
      setFramesstickers(stickersData)
      console.log(currentImage)
      setStep(step + 1)
    } else if(step === 3) {
      onCapture(currentImage.img3)
      setStep(step + 1)
      console.log(currentImage)
    }
  }
  function handlePrev() {
    if (step === 2) {
      /* set stickers */ 
      handleClear()
    } else if(step === 3) {
      setFramesstickers(framesData)
      setStep(step - 1)
    }
  }
  function handleCanPlay() {
    console.log(videoRef)
    console.log('handleCanPlay', videoRef.current.clientHeight, videoRef.current.clientWidth)
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  function handleResize(contentRect) {
    console.log(contentRect.bounds)
    setContainer({
      width: contentRect.bounds.width,
      // height: Math.round(contentRect.bounds.width / aspectRatio)
      height: contentRect.bounds.height
    });
  }

  function handleClear() {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true)
    setCurrentImage()
    onClear();
    setStep(1)
    setFramesstickers(framesData)
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper ref={wrapperRef}>
          <Header>
            VIRTUAL PHOTOBOOTH
          </Header>
            {/* Step 1 */}
            {!isVideoPlaying && <Loading width={wrapperRef.current &&  Math.round(wrapperRef.current.getBoundingClientRect().width / 1.2)}><i>Loading....</i></Loading>}
            <Container
              ref={measureRef}
              maxHeight={videoRef.current && videoRef.current.videoHeight}
              maxWidth={videoRef.current && videoRef.current.videoWidth} 
              style={{
                height: `480px`
              }}
              id="previewWrapper"
            >
              <Video
                ref={videoRef}
                hidden={!isVideoPlaying}
                onCanPlay={handleCanPlay}
                autoPlay
                playsInline
                muted
                style={{
                  top: `-${offsets.y}px`,
                  left: `-${offsets.x}px`,
                }}
              />
              <Canvas
                ref={canvasRef}
                width={container.width}
                height={container.height}
              />
              <Flash
                flash={isFlashing}
                onAnimationEnd={() => setIsFlashing(false)}
              />
            </Container>
            {/* Step 2 */}
            { step === 2 && ( 
              <Frames 
                currentImage={currentImage.img1 && currentImage.img1}
                maxHeight={canvasRef.current && canvasRef.current.height}
                maxWidth={canvasRef.current && canvasRef.current.width}
                step={step}
                framesstickers={framesstickers}
                setCurrentImage={dataURL => setCurrentImage({...currentImage, img2: dataURL})}
                style={{
                  width: `${container.width}px`,
                  height:`${container.height}px`
                }} 
              />
            )}
            { step === 3 && ( 
              <Frames 
                currentImage={currentImage.img2 && currentImage.img2}
                maxHeight={canvasRef.current && canvasRef.current.height}
                maxWidth={canvasRef.current && canvasRef.current.width}
                framesstickers={framesstickers}
                step={step}
                setCurrentImage={dataURL => setCurrentImage({...currentImage, img3: dataURL})}
                style={{
                  width: `${container.width}px`,
                  height:`${container.height}px`
                }} 
              />
            )}
            { step === 4 && (
              <Sharer 
                currentImage={currentImage.img3 && currentImage.img3}
                maxHeight={canvasRef.current && canvasRef.current.height}
                maxWidth={canvasRef.current && canvasRef.current.width}
                handleClear={handleClear}
              />
            )}
            { step < 4 && (
              <Buttons className={`step-${step}`}>
                <Pagelabel>{step === 2 ? 'CHOOSE FRAME' : step === 3 ? 'ADD STICKER' : ''}</Pagelabel>
                <div className="buttons--wrapper">
                  <Prev onClick={handlePrev}></Prev>
                  <Capture 
                    className={isCanvasEmpty ? "capture" : "retry"}
                    onClick={isCanvasEmpty ? handleCapture : handleClear} 
                    disabled={!isVideoPlaying}
                    >
                  </Capture>
                  <Next onClick={handleNext}></Next>
                </div>
            </Buttons>
            )}
            
          {/* custom photo */}

        </Wrapper>

      )}
    </Measure>
  );
}
