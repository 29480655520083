import styled from "styled-components";
import bgDownload from '@assets/icons/icon-download.png';
import bgRetake from '@assets/icons/icon-retake.png';

export const Wrapper = styled.div`

  position: absolute;
  display: flex;
  margin-top: 155px;
  margin-left: 10px;
  margin-right:10px;
  flex-direction: column;
  width: calc(100% - 20px);
  left: 0;
  @media only screen and (max-width: 480px) {
        margin-top: 116px;
        max-height: 400px;
    }
  img {
      width: 100%;
  }
`;

export const ShareButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  @media only screen and (max-width: 480px) {
        margin-top: 20px; 
    }
`

export const Download = styled.button `
  border: 0;
  background-image: url(${bgDownload});
  width: 180px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-bottom: 15px;
  image-rendering: optimizeQuality;
  image-rendering: -webkit-optimize-contrast;

  @media only screen and (max-width: 480px) {
    width: 150px;
    height: 41px;
    margin-bottom: 10px;
  }
`

export const Retake = styled(Download) `
  background-image: url(${bgRetake});
`